<template>
  <div>
    <header-slot>
      <template #actions>
        <div>
          <b-button variant="danger" @click="clearEverything">
            Clear All
          </b-button>
        </div>
      </template>
    </header-slot>
    <b-card>
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <span
            v-if="filteredItems.length !== 0"
            class="k-font-weight-bold font-medium-1"
            >Showing Logs: {{ dateFilter }}</span
          >
          <span
            v-if="filteredItems.length === 0"
            class="k-font-weight-bold font-medium-1"
            >There are no logs</span
          >
        </div>
        <div class="d-flex justify-content between align-items-center">
          <feather-icon
            v-if="dateFilter == currentDate"
            v-b-tooltip.hover.top="'Refresh'"
            class="cursor-pointer text-primary m-1"
            size="20"
            icon="RefreshCcwIcon"
            @click="onChangeDate"
          />

          <span>Select Date: </span>
          <v-select
            class="ml-1"
            :options="datesOptions"
            v-model="dateFilter"
            style="width: 160px !important"
            :clearable="false"
            @input="onChangeDate"
          ></v-select>
          <feather-icon
            v-if="dateFilter"
            v-b-tooltip.hover.top="'Clear the logs for the selected date!'"
            class="cursor-pointer text-danger ml-1"
            size="20"
            icon="TrashIcon"
            @click="clearByDate"
          />
        </div>
      </div>
      <b-table
        no-border-collapse
        class="mt-1 position-relative"
        :fields="fields"
        show-empty
        sticky-header="50vh"
        primary-key="id"
        responsive="sm"
        :items="filteredItems"
        :busy="isBusy"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>
        <template #cell(time)="data">
          <!-- <b-badge :class="returnColorBgType(data.item.type)">{{
            data.item.type
          }}</b-badge> -->

          <div>{{ data.item.time | Iso2CADateWithHour }}</div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import vSelect from "vue-select";
import LogReaderService from "@/views/log-reader/log-reader.service";
import moment from "moment";
export default {
  components: {
    vSelect,
  },
  async created() {
    this.$store.commit("appConfig/UPDATE_NAVBAR_CONFIG", { type: "hidden" });
    this.$store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", true);
    await this.getLogs();
  },
  destroyed() {
    this.$store.commit("appConfig/UPDATE_NAVBAR_CONFIG", {
      type: "sticky",
    });
    this.$store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", false);
    this.$store.commit("appConfig/UPDATE_LAYOUT_TYPE", "vertical");
  },
  data() {
    return {
      isBusy: false,
      filteredItems: [],
      datesOptions: [],
      finishedLoading: false,
      currentDate: moment().format("YYYY-MM-DD"),
      fields: [
        {
          key: "time",
          label: "Time",
        },
        {
          key: "path",
          label: "Path",
        },
        {
          key: "message",
          label: "Message",
        },
        {
          key: "type",
          label: "Type",
        },
      ],
      statusBadges: [0, 0, 0, 0, 0, 0, 0, 0],
      dateFilter: "",
      filename: "",
    };
  },
  methods: {
    returnColorBgType(type) {
      switch (type) {
        case "INFO":
          return "info-bg";
        case "EMERGENCY":
          return "emergency-bg";
        case "CRITICAL":
          return "critical-bg";
        case "ALERT":
          return "alert-bg";
        case "ERROR":
          return "error-bg";
        case "WARNING":
          return "warning-bg";
        case "NOTICE":
          return "notice-bg";
        case "DEBUG":
          return "debug-bg";
      }
    },
    clearFilters() {
      this.statusBadges = [0, 0, 0, 0, 0, 0, 0, 0];
    },
    filterByType(index, type) {
      this.statusBadges = [0, 0, 0, 0, 0, 0, 0, 0];
      this.$set(this.statusBadges, index, 1);
    },
    async getLogs(date = null) {
      try {
        const params = {
          date: date,
        };
        this.isBusy = true;
        this.finishedLoading = false;
        const { data, status } = await LogReaderService.getLogDetailNest(
          params
        );
        if (status == 201 && data) {
          this.datesOptions = data.available_log_dates;

          if (!date) {
            this.dateFilter = data.available_log_dates[0];
          }
          this.filteredItems = data.logs.map((item) => {
            return JSON.parse(item);
          });
        } else {
          this.datesOptions = [];
          this.filteredItems = [];
          this.dateFilter = null;
        }
      } catch (e) {
        this.showErrorSwal(e);
      } finally {
        this.isBusy = false;
        this.finishedLoading = true;
      }
    },
    async onChangeDate() {
      await this.getLogs(this.dateFilter);
    },
    async clearByDate() {
      try {
        const response = await this.showConfirmSwal();
        if (response.isConfirmed) {
          await LogReaderService.deleteLogListNest({
            date: this.dateFilter,
          });
          this.showSuccessSwal();
          await this.getLogs();
        }
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
    async clearEverything() {
      try {
        const response = await this.showConfirmSwal();
        if (response.isConfirmed) {
          await LogReaderService.deleteLogListNest({ all: true });
          this.showSuccessSwal();
          await this.getLogs();
        }
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
  },
};
</script>

<style scoped>
.info-bg {
  background-color: #6bb5b5 !important;
}
.emergency-bg {
  background-color: #ff6060 !important;
}
.critical-bg {
  background-color: #de4f4f !important;
}
.alert-bg {
  background-color: blue !important;
}
.error-bg {
  background-color: red !important;
}
.warning-bg {
  background-color: #f7be57 !important;
}
.notice-bg {
  background-color: #8f5fe8 !important;
}
.debug-bg {
  background-color: #343a40 !important;
}
</style>
